import React, { useState, useEffect } from 'react';
import { 
    Container, Row, Col,
    Button
 } from 'reactstrap';
// import { Requests } from './../util/api/request'
import ScoutingBook from './../components/ScoutingBook'
import Guide from './Guide'

const Inventory = (props) => {

    const [scoutingBookModal, setScoutingBookModal] = useState(false);
    const toggleScoutingBookModal = () => setScoutingBookModal(!scoutingBookModal);

    const [guideModal, setGuideModal] = useState(false);
    const toggleGuideModal = () => setGuideModal(!guideModal);

    // const [scoutingData, setScoutingData] = useState([]);

    // const fetchScoutData = () => {
    //     Requests.getScoutBookData()
    //       .then(results => setScoutingData(results));
    //   }
    // useEffect(() => {
    //     fetchScoutData()
    //   }, []);

return (
    <>
    <Guide
        modal={guideModal}
        toggle={toggleGuideModal}
    />
    <ScoutingBook
    modal={scoutingBookModal}
    toggle={toggleScoutingBookModal}
    setCurrentScoutSelection={props.setCurrentScoutSelection}
    // mapItemCenter={props.mapItemCenter}
    />
    <div className="inventoryWrapper">
        <div className="inventoryButtonsGroup">
        {/* <Container> */}
        <Row style={{margin:'0px'}}>
        <Col style={{alignItems:'center', justifyContent:'center', display:'flex'}}>
        <Button className='inventoryButton' outline color="primary" onClick={toggleGuideModal}>Guide</Button> 
        <Button className='inventoryButton' outline color="secondary" onClick={toggleScoutingBookModal}>Scouting Book</Button>
        <Button className='inventoryButton' outline color="warning">My Bag</Button>
        </Col>
        </Row>
        {/* </Container> */}
            
        </div>
    </div>
    </>
)


}

export default Inventory;
import axios from 'axios';
import firebase from "firebase/app";
import 'firebase/firestore';
import { auth } from '../../firebase';
// export default () => axios.get('/test');
// var db = firebase.firestore();
// console.log("db",db)



export const Requests = {
    defs: () => axios.get('https://macrostrat.org/api/defs'),
    geolUnitsByMapId: (map_id) => axios.get(`https://macrostrat.org/api/geologic_units/map?map_id=${map_id}`),
    geolLithologiesByLithId: (lith_ids) => axios.get(`https://macrostrat.org/api/defs/lithologies?lith_id=${lith_ids}`),

    addToScoutBookByGuideId: (guideId) => {
        console.log("posting to user's scout book with guideId..",guideId)
        var userCollection = firebase.firestore().collection("Users")

        // TO-DO: Need to get User ID/Document ID, and Need to get Rock Guide Entry from Guide table ...
        userCollection.doc(auth.currentUser.uid).collection("scouting_book").add({
            guide_id: "KgZYMFoPUfRKOMRcB0Oy ",
            map_id: "11111",
            name: "Mudstone3"
        })
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
    },

    addToScoutBookByGuideId2: (itemName,selectedMapData) => {


        console.log("Searching for Rock and Adding to User's Scouting Book...")
        console.log("Searching for rock: ",itemName)
        console.log("Searching for rock with selectedMapData: ",selectedMapData)
        let doc_id;
        let doc_data;
        var guideRef = firebase.firestore().collection("Guide")
        var query = guideRef.where("name", "==", itemName).get()
        .then(results => {
            // console.log("query result: ",results)
            results.forEach(doc => {
                console.log("Guide Name: ",doc.data().name)
                // console.log("Guide Definition: ",doc.data().definition)
                console.log(`${doc.id} => ${doc.data()}`);
                doc_id = doc.data().name
                doc_data = doc.data()
                
            })
            console.log("Found Rock in Guide: ",doc_id)
            console.log("Updating Object to Contain selectedMapData..")
            doc_data.map_name = selectedMapData.name
            doc_data.map_id = selectedMapData.map_id
            doc_data.age = selectedMapData.age
            doc_data.best_age_bottom = selectedMapData.best_age_bottom
            doc_data.best_age_top = selectedMapData.best_age_top
            doc_data.clicked_lng = selectedMapData.clicked_lng
            doc_data.clicked_lat = selectedMapData.clicked_lat
            doc_data.clicked_zoom = selectedMapData.clicked_zoom

            doc_id = doc_id + "_" + doc_data.map_id

            console.log("Adding Guide Object to User's Scouting Book..", doc_data)
            console.log("Adding Guide Object to User's Scouting Book with ID..", doc_id)

            // TO-DO: Need to get User ID/Document ID, and Need to get Rock Guide Entry from Guide table ...
            var userCollectionScoutingBook = firebase.firestore().collection("Users")
            .doc(auth.currentUser.uid)
            .collection("scouting_book")
            .doc(doc_id)

            // TEMP: Disable to prevent actual INSERT/MERGE

            userCollectionScoutingBook.set(doc_data, { merge: true })
            .then(() => {
                console.log("Document written to Scouting Book!");
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
           
            

        })
        // Requests.addToScoutBookByGuideId2(doc_id)
    
        

        
    },

    getScoutBookData: async () => {
        // console.log("GETing scout book data..")
        // console.log("current user: ",currentUserUid)
        // console.log("current user: ",auth.currentUser.uid)

        var scoutBook = []
        var userCollection = firebase.firestore().collection("Users")
        await userCollection.doc(auth.currentUser.uid).collection("scouting_book").get().then((querySnapshot) => {
            console.log("scout querySnapshot: ",querySnapshot)
            // console.log("scout querySnapshot docs: ",querySnapshot.docs)
            querySnapshot.forEach((doc) => {
                // Make new data object with Document ID ALSO Included..
                var docData = {id: doc.id, ...doc.data()}

                // doc.data()['id'] = doc.id
                // console.log("Doc id",doc.id)
                // console.log ("Doc Data",data)
                
                // console.log("Rock Name: ",doc.data().name)
                // console.log("Map id: ",doc.data().map_id)
                // console.log(`${doc.id} => ${doc.data()}`);
                // scoutBook.push({
                //     id: doc.id,
                //     name: doc.data().name,
                //     map_id: doc.data().map_id,
                //     guide_id: doc.data().guide_id
                // })

                // Push entire data object from User's Scouting Book..
                scoutBook.push(docData)
            });
            console.log("Scout book results: ",scoutBook)
            // return scoutBook
            
        })
        
        return scoutBook
        
    },

    removeFromScoutBook: async (itemName) => {
        console.log("Removing item from scouting book..",itemName)
        var userCollection = firebase.firestore().collection("Users")
        userCollection.doc(auth.currentUser.uid).collection("scouting_book").doc(itemName).delete()
        .then(() => {
            console.log("Document successfully deleted!");
            
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    },

    getGuideObjectByName: () => 
    firebase.firestore().collection("Guide").get().then((querySnapshot) => {
        
        querySnapshot.forEach((doc) => {
            // console.log("Guide Name: ",doc.data().name)
            // console.log("Guide Definition: ",doc.data().definition)
            console.log(`${doc.id} => ${doc.data()}`);
        });
    }),

    getGuideObjectByCommonName: async (itemName) => {

        let doc_data ={}
        var guideRef = firebase.firestore().collection("Guide")
        var query = await guideRef.where("name", "==", itemName).get()
        .then(results => {
            // console.log("query result: ",results)
            results.forEach(doc => {
                // console.log("Guide Data: ",doc.data())
                // console.log("Guide Definition: ",doc.data().definition)
                // console.log(`${doc.id} => ${doc.data()}`);
                doc_data = doc.data()
                
            })
        })
        return doc_data
    
    },

    getGuideObjectsBySubType: async (subType) => {
        let guideItems = []
        var guideRef = firebase.firestore().collection("Guide")
        var query = await guideRef.where("sub_type", "==", subType).get()
        .then(results => {
            results.forEach(doc => {
                guideItems.push(doc.data())
            })
        })
        // console.log("returning guideItems",guideItems)
        return guideItems

    }

    

    
    // firebase.firestore().collection("Guide").get().then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //         console.log("Guide Name: ",doc.data().name)
    //         console.log("Guide Definition: ",doc.data().definition)
    //         console.log(`${doc.id} => ${doc.data()}`);
    //     });
    // })

}
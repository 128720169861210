import React, { } from 'react';
import {
    Button, ButtonGroup,
    Card,
    CardDeck,
    CardBody,
    CardTitle,
    CardText,
    CardImg,
    CardImgOverlay,
    CardFooter,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row
} from 'reactstrap';
import { Requests } from './../util/api/request'
import sampleImg from '../assets/sulfur_shadow.png'
import GuideGlossary from './GuideGlossary'

const Guide = (props) => {
    const {
        modal,
        toggle
      } = props;
    return (
        <div>
            <Modal size='lg' isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Guide</ModalHeader>
                <ModalBody>
                    <p><h1>Welcome to the Guide!</h1></p>

                    <p><h2>What's New</h2></p>
                    <p><h2 style={{textAlign:'center'}}>Glossary</h2></p>

                    {/* <div className='guideGlossaryButtons'> */}
                    
                    <GuideGlossary
                    />
                    
                    
                    
                    
                    
                </ModalBody>
            <ModalFooter 
            // style={{borderTop:'0px'}}
            >
                <Button outline color="secondary" onClick={toggle}>Return</Button>
            </ModalFooter>
        </Modal>
        </div>
    )
}

export default Guide;
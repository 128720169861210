import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,
Media
 } from 'reactstrap';
import logoImg from '../assets/Logo.svg' 

const NavBar = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar color="faded" light>

        <NavbarBrand href="/geology" className="me-auto"> 
        <img style={{paddingRight:'3px'}}width="55px" height="auto" className="img-responsive" src={logoImg}  alt="logo" />
        Sole Geology</NavbarBrand>

        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/">Return to Dashboard</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="/profile">Profile</NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavBar;

import React, { useRef, useState } from 'react'
import { Card, Button, CardTitle, CardText,
    Col, Form, FormGroup, Label, Input, FormText, Alert
 } from 'reactstrap';
 import { useAuth } from '../contexts/AuthContext'
 import { Link, useHistory } from 'react-router-dom'

export default function ForgotPassword() {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)


    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage('Check your inbox for further instructions')
        } catch {
            setError('Failed to reset password')
        }
        setLoading(false)

        
    }

    return (
        <div>
        <Card body >
            <CardTitle className="text-center" tag="h4">Password Reset</CardTitle>
            {error && <Alert color="danger"> {error} </Alert>}
            {message && <Alert color="success"> {error} </Alert>}
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="exampleEmail" sm={2}>Email</Label>
                    <Col sm={10}>
                    <Input type="email" innerRef={emailRef} name="email" id="exampleEmail" placeholder="Email" />
                    </Col>
                </FormGroup>
                <Button disabled={loading} color="primary" block>Reset Password</Button>
            </Form>
            <div>
                <Link to='/login'>Login</Link>
            </div>
        </Card>
        <div>
            Need an account? <Link to='/signup'>Sign up</Link>
        </div>

        </div>
    )
}

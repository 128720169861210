import React from 'react'
import { Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavLink,
    Row, Col,
    Card, CardTitle, CardText, CardImg, CardImgOverlay,
    Button
} from 'reactstrap';
import DashboardNav from './Dashboard/DashboardNav'
import logoImg from '../assets/Logo.svg' 

export default function Dashboard() {
    return (
        <div>
            <DashboardNav/>

            <Row xs="4">
            <Col><br></br></Col>
            </Row>

            <Row xs="4">
                <Col xs="6"> 
                <Card style={{padding:'5px', margin:'5px'}}>
                    <CardTitle tag="h5">Geology</CardTitle>
                    <CardText>Enter the Sole Geology Map</CardText>
                    <Button color="primary" href="/geology">Let's go</Button>
                </Card>

            </Col>
                {/* <Col xs="6">
                    <Row>
                        Scouting
                    </Row>
                    <Row>
                        My Bag
                    </Row>
                </Col> */}
            </Row>
        </div>
    )
}

import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import DashboardNav from './Dashboard/DashboardNav'

export default function About() {
    return (
        <div>
            <Col>
            <DashboardNav/>
            <h3>About</h3>
            <p>Solemarks and Sole Geology is designed and authored by Devin Rocco.</p>
            <p>Initially published in June 2021.</p>
            <h4>Data sources and Attribution:</h4>

            <p>Sole Geology primary map layer and dataset is provided by <a href="https://macrostrat.org/" target="_blank">Macrostrat</a></p>

            <p>Specimen definitions and photos are provided by wikipedia.</p>

            <p>Attribution-ShareAlike 3.0 Unported (CC BY-SA 3.0) <br></br><a href="https://creativecommons.org/licenses/by-sa/3.0/" target="_blank"> Released under CC-BY-SA</a></p>
            
            <p>Icons: https://fontawesome.com/license</p>
            </Col>

        </div>
    )
}

import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,
Media, Alert
 } from 'reactstrap';
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import logoImg from '../../assets/Logo.svg' 

export default function DashboardNav() {
    const [collapsed, setCollapsed] = useState(true);
    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    const toggleNavbar = () => setCollapsed(!collapsed);


    async function handleLogout() {
        setError('')
        try {
            await logout()
            history.push('/login')
        } catch {
            setError('Failed to log out')
        }
    }

    return (
        <div>
          <Navbar color="faded" light>
    
            <NavbarBrand href="/" className="me-auto"> 
            <img style={{paddingRight:'3px'}}width="55px" height="auto" className="img-responsive" src={logoImg}  alt="logo" />
            Sole Marks</NavbarBrand>
            {/* <Nav pills>
            <NavItem>
            <NavLink href="#">Logout</NavLink>
            </NavItem>
            </Nav> */}
    
            
            <NavbarToggler onClick={toggleNavbar} className="me-2" />
            
            <Collapse isOpen={!collapsed} navbar>
            {error && <Alert color="danger"> {error} </Alert>}

              <Nav navbar>
              
                <NavItem>
                  <NavLink href="/profile">Profile
                      {/* <Link to='/profile'>Profile</Link> */}
                      </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/about">About
                      </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/">Dashboard
                      </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href=''
                  onClick={handleLogout}
                  >Logout</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink disabled href="#">Logged in as {currentUser.email}</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      );
}
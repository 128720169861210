// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Map from './Map';
import Inventory from './components/Inventory'
import NavBar from './components/NavBar'
// import firebase from "firebase";
import 'firebase/firestore';
// import firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import GoogleFontLoader from 'react-google-font-loader';

import Signup from './components/Signup'
import { Container, Row, Col } from 'reactstrap';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import About from './components/About'
import PrivateRoute from './components/PrivateRoute'
import LandingHome from './components/Landing/LandingHome'
import ForgotPassword from './components/ForgotPassword'
import DashboardProfile from './components/Dashboard/DashboardProfile'

import SoleGeologyMain from './components/SoleGeology/SoleGeologyMain'


function App() {

  return (
        // <Container style={{padding:'0px'}}>

        <>
        <GoogleFontLoader
            fonts={[
              {
                font: 'Avenir Next',
                weights: [400, '400i'],
              },
              {
                font: 'Roboto Mono',
                weights: [400, 700],
              },
            ]}
            subsets={['cyrillic-ext', 'greek']}
          />
        <div 
        // className='font-link'
        >
          

          <Router>
            <AuthProvider>
              <Switch>
                <PrivateRoute exact path='/' component={Dashboard}/>
                <PrivateRoute path='/profile' component={DashboardProfile} />
                <PrivateRoute exact path='/geology' component={SoleGeologyMain}/>
                <Route path ='/signup' component={Signup} />
                <Route path ='/login' component={Login} />
                <Route path='/forgot-password' component={ForgotPassword} />
                <Route path ='/about' component={About} />
              </Switch>
            </AuthProvider>
          </Router>
        </div>
      </>
    
  );
}

export default App;

import React, { useRef, useEffect, useState, useReducer } from 'react';
import mapboxgl from 'mapbox-gl';
// import './Map.css';
import NavBar from './components/NavBar'
import Inventory from './components/Inventory'
import ActiveCardGeology from './components/ActiveCardGeology'
import CardManagerGeology from './components/CardManagerGeology'
// import "mapbox-gl/dist/mapbox-gl.css"
import WelcomeToast from './components/WelcomeToast'
import InfoToast from './components/InfoToast'
import ClickedResultsAlert from './components/ClickedResultsAlert'
import CollectViewInfoToast from './components/CollectViewInfoToast'
import ToolKit from './components/ToolKit';
import { isCompositeComponentWithType } from 'react-dom/test-utils';
import bbox from '@turf/bbox';
import polygonToLine from '@turf/polygon-to-line';
import flatten from '@turf/flatten'
import { Requests } from './util/api/request'

// import 'mapbox-gl/dist/mapbox-gl.css';


mapboxgl.accessToken =
  'pk.eyJ1Ijoicm9jay1vbiIsImEiOiJjazdmamdqM2swM2tiM2RzMTVzZHo0azE4In0.R7p1gm6-ACCcAzC68BfdXw';

const Map = (props) => {
    const mapContainerRef = useRef(null);
    const [map, setMap] = useState(null);
    const [currentGeology, setCurrentGeology] = useState({})

    const [showWelcomeToast, setShowWelcomeToast] = useState(false);
    const toggleShowWelcomeToast = () => setShowWelcomeToast(!showWelcomeToast);

    const [showInfoToast, setShowInfoToast] = useState(false);
    const toggleShowInfoToast = () => setShowInfoToast(!showInfoToast);

    const [showClickedResultsAlert, setShowClickedResultsAlert] = useState(false);
    const toggleClickedResultsAlert = () => setShowClickedResultsAlert(!showClickedResultsAlert);
    const [clickedResultsAlertText, setClickedResultsAlertText] = useState('Nothing up here.. try searching lower')

    // Keep track if results/items are present after map click - used to trigger alert OR scouting UI
    const [clickedResultsFound, setClickedResultsFound] = useState(false)
    // const toggleClickedResultsFound = () => setClickedResultsFound(!clickedResultsFound)

    const [modalCollectViewInfoToast, setModalCollectViewInfoToast] = useState(false);
    const toggleModalCollectViewInfoToast = () => setModalCollectViewInfoToast(!modalCollectViewInfoToast);

    const [bagContents, setBagContents] = useState({

    })

    function flyToScoutSelection() {
      if (map) {
        map.flyTo({center: [props.currentScoutSelection.clicked_lng,props.currentScoutSelection.clicked_lat],
          zoom: props.currentScoutSelection.clicked_zoom,
          speed: 1.5,
          curve: 1,
          easing(t) {
          return t;
          }
         });
      }
      
    }

    function zoomMap(direction) {
      if (map) {
        if (direction === 'in') {
          map.zoomIn()
        } else if (direction === 'out' ) {
          map.zoomOut()
        }
      }
      
    }

    function locateMap() {
      if (map) {
        console.log("locating..")
      }
      
    }

    useEffect(() => {
      flyToScoutSelection()
    },[props.currentScoutSelection])

    
    // const mapItemCenter = (props) => {
    //   console.log("Attemping to center map on selected item and load image box..")
    
    
    // }
    
    console.log("Cur geol",currentGeology)

    useEffect(() => {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-86.8127673, 40.0063954],
        zoom: 2.8,
        attributionControl: false
      });

      // map.addControl(new mapboxgl.AttributionControl(), 'bottom-right');

      // map.addControl(
      //   new mapboxgl.GeolocateControl({
      //   positionOptions: {
      //   enableHighAccuracy: true
      //   },
      //   trackUserLocation: true
      //   })
      // );

      // Add zoom and rotation controls to the map.
      // map.addControl(new mapboxgl.NavigationControl());

      map.on('load', () => {
  
        map.addSource('macrostrat', {
          'type': 'vector',
          'tiles': [
          'https://tiles.macrostrat.org/carto/{z}/{x}/{y}.mvt'
          ]
          // 'minzoom': 6,
          // 'maxzoom': 14
          });
          
        map.addLayer( {
          'id': 'macrostrat',
          'type': 'fill',
          'source': 'macrostrat',
          'source-layer': 'units',
          paint: {
            'fill-color': 
              ["get", 'color'],
              'fill-opacity': 0.25
            
            // "fill-outline-color": '#f17105'
          }
          
        })

        // For outlining the polygon region when selected
        map.addSource('selected-region-outline', {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            "properties": {},
            'geometry': {
              "type": "MultiLineString",
              "coordinates": [
              ]
            }
          }
          });
          map.addLayer({
            'id': 'selected-region-outline',
            'type': 'line',
            'source': 'selected-region-outline',
            'paint': {
              // 'fill-outline-color': 'white',
              // 'fill-color': "hsla(100, 50%, 50%,0.3)"
              'line-color': 'white',
              'line-width': 2
            }
            });

            


        map.on('click', 'macrostrat', function (e) {
          var coordinates = e.features[0].geometry.coordinates.slice();
          var description = e.features[0].properties;
          console.log("click e",e)
          console.log("click e Z00m",map.getZoom())
          console.log("coordinates",coordinates)
          // console.log("desc",description)

          // What is ""...prevState" doing exactly?
          setCurrentGeology((prevState) => ({
            ...prevState,
            map_id: e.features[0].properties.map_id,
            name: e.features[0].properties.name,
            lith: e.features[0].properties.lith,
            best_age_bottom: e.features[0].properties.best_age_bottom,
            best_age_top: e.features[0].properties.best_age_top,
            age: e.features[0].properties.age,
            clicked_lng: e.lngLat.lng,
            clicked_lat: e.lngLat.lat,
            clicked_zoom: map.getZoom() ?? 0
          }))

          toggleShowInfoToast()

          // Get data for selected region
          var features = map.queryRenderedFeatures(e.point);
          // console.log("geom of ftr",features[0].geometry) 
          // console.log("flatten poly: ",flatten(features[0].geometry))
          // console.log("Poly to line:",polygonToLine(features[0].geometry))
          let selectedRegionLineRepresentation
          try { 
            selectedRegionLineRepresentation = polygonToLine(features[0].geometry) }
             catch (error) {
              console.error(error);
              selectedRegionLineRepresentation = {}
          }
          map.getSource('selected-region-outline').setData(selectedRegionLineRepresentation);
          
          var bboxRegion = bbox(features[0].geometry)
          console.log("Region bbox:",bboxRegion)
          // map.fitBounds(bboxRegion,
          //    {
          //     //  padding: 200
          //     //  maxZoom: 3
          //     //  linear: false
          //     } // was 350
          //    );

             // Experiment with FlyTo instead of fitBounds..
            map.flyTo({center: e.lngLat,
               zoom: map.getZoom() + 0.5,
               speed: 1.5,
               curve: 1,
               easing(t) {
               return t;
               }
              });


          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          // coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          // }
           
          // new mapboxgl.Popup()
          // .setLngLat(coordinates)
          // .setHTML(description)
          // .addTo(map);


          // Test Firestore call..
          // Requests.getGuideObjectByName()
          });

      });
      // map.resize()
      setMap(map);
    // Clean up on unmount
    return () => map.remove();
  }, []);
    
  return (
    <div>
      
      <div className='map-container' ref={mapContainerRef} />

      <CollectViewInfoToast
        isOpen={modalCollectViewInfoToast}
        toggle={toggleModalCollectViewInfoToast}
        bagContents={bagContents}
        selectedMapData={currentGeology}
        type={"geology"} 
        toggleClickedResultsAlert={setClickedResultsFound}
        />

      <div className="infoToast">
      <WelcomeToast
        toggle={toggleShowWelcomeToast}
        isOpen={showWelcomeToast}
        />

      <ToolKit
      mapZoomControl={zoomMap}
      mapLocateControl={locateMap}
      />

      <InfoToast
       toggle={toggleShowInfoToast} 
       toggleCollectView={toggleModalCollectViewInfoToast}
       toggleClickedResultsAlert={toggleClickedResultsAlert}
       isOpen={showInfoToast}
       type={"geology"} 
       selectedMapData={currentGeology}
       clickedResultsFound={clickedResultsFound}
        />

      </div>

      <ClickedResultsAlert
          isOpen={showClickedResultsAlert}
          toggle={toggleClickedResultsAlert}
          alertText={clickedResultsAlertText}
        />

      {/* <ActiveCardGeology geolData={currentGeology} /> */}
      
      {/* <CardManagerGeology geolData={currentGeology} /> */}
      
      {/* <Inventory /> */}
      
    </div>
  );
}

export default Map;
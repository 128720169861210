import React, { useState } from 'react';
import { Button, Toast, ToastBody, ToastHeader } from 'reactstrap';


const WelcomeToast = (props) => {
    // const { buttonLabel } = props;
    
//   console.log("Showing toast:",props.isOpen)
    return (
      <div>
        <Toast isOpen={props.isOpen}>
          <ToastHeader toggle={props.toggle}>Welcome!</ToastHeader>
          <ToastBody>
            Start exploring the map to discover rocks from around the world..
            <br></br>Find something interesting? Add it to your Scouting Book for later.
            When you are nearby or find it outdoors using <a href="url"> the mobile app </a>
            you can add it to your personalized bag.
          </ToastBody>
        </Toast>
      </div>
    );
  }
  
  export default WelcomeToast;
import React, { useEffect, useState } from 'react';
import {
    Button, ButtonGroup,
    Card,
    CardDeck,
    CardBody,
    CardTitle,
    CardText,
    CardImg,
    CardImgOverlay,
    CardFooter,
    Col,
    Container,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row
} from 'reactstrap';
import { Requests } from './../util/api/request'
import sampleImg from '../assets/sulfur_shadow.png'


const GuideGlossary = (props) => {

  const [rSelected, setRSelected] = useState(null);
  const [guideItems, setGuideItems] = useState(null)

  function capitalize (string) {
    return string[0].toUpperCase() + string.slice(1)
  }

  useEffect(() => {
    console.log("Calling for Guide Items")

    const getSubtype = (subType) => {
        switch (subType) {
            case 1:
                return 'igneous'
            case 2:
                return 'sedimentary'
            case 3:
                return 'metamorphic'
            default:
                return null
        }
    }

    if (rSelected) {

        Requests.getGuideObjectsBySubType(getSubtype(rSelected)).then((results) => {
            console.log("Returned Guide Items: ",results)
            setGuideItems(results)
        })
        
    }
    
  },[rSelected]);

    return (
        <div>
                    {/* <Row>
                    <Button className='guideGlossaryButtons' color="primary" size="lg" block>Igneous</Button>
                    </Row>
                    <Row>
                    <Button className='guideGlossaryButtons' color="warning" size="lg" block>Sedimentary</Button>
                    </Row>
                    <Row>
                    <Button className='guideGlossaryButtons'color="info" size="lg" block>Metamorphic</Button>
                    </Row> */}
                    <Row>
                    <ButtonGroup className='guideGlossaryButtons'>
                        <Button color="primary" onClick={() => setRSelected(1)} active={rSelected === 1}>Igneous</Button>
                        <Button color="primary" onClick={() => setRSelected(2)} active={rSelected === 2}>Sedimentary</Button>
                        <Button color="primary" onClick={() => setRSelected(3)} active={rSelected === 3}>Metamorphic</Button>
                    </ButtonGroup>
                    </Row>
                    {/* </div> */}

                    <div  
                    // className='guideCardContainer'
                    >
                    {guideItems 
                    //  ?  
                    ? <CardDeck style={{alignItems: 'center', justifyContent: 'center'}}>
                        {guideItems.map((item,index) => 
                        <div
                             className='guideCard'
                              key={item.name}> 
                    <Card>  
                        <CardImg
                         className='guideCardImg'
                         src={item.img_url} alt="Card image cap" />
                         
                         <CardImgOverlay>
                         {/* <CardTitle tag="h6">{item.name}</CardTitle> */}
                         <CardText
                         style={{backgroundColor: 'rgba(0, 0, 0, 0.65)', borderColor: 'rgba(0, 0, 0, 0.65)', borderRadius: '5px', color: 'white', textAlign: 'center'}}
                         >
                            <small>{capitalize(item.name)}</small>
                        </CardText>
                         </CardImgOverlay>
                    </Card>
                     </div>)}
                     </CardDeck>
                    : <div></div>
                    }
                   



                    {/* <Row>
                    <div className='guideCard'>
                    <Card>  
                        <CardBody
                        style={{padding: "0px", textAlign: 'center', fontSize:'medium'}}
                        >
                        <CardImg
                        //  className='guideCardImg'
                         src={sampleImg} alt="Card image cap" />
                         
                         <CardFooter style={{backgroundColor:'white', outlineStyle:'none'}}
                         className='guideCardFooter'
                         >
                             <p>Serpentinite</p></CardFooter>
                         </CardBody>
                    </Card>
                    </div>

                    <div className='guideCard'>
                    <Card>  
                        <CardBody
                        style={{padding: "0px", textAlign: 'center', fontSize:'medium'}}
                        >
                        <CardImg
                        //  className='guideCardImg'
                         src={sampleImg} alt="Card image cap" />
                         
                         <CardFooter style={{backgroundColor:'white', outlineStyle:'none'}}
                         className='guideCardFooter'
                         >
                             <p>Serpentinite</p></CardFooter>
                         </CardBody>
                    </Card>
                    </div>
                    
                    </Row> */}
                    </div>
        </div>
    )
}

export default GuideGlossary;
import React, { useState } from 'react';
import { Button, Toast, ToastBody, ToastHeader, Badge } from 'reactstrap';


const InfoToast = (props) => {
    // const { buttonLabel } = props;
    
//   console.log("Cur data for InfoToast: ",props.selectedMapData)

  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

  const scoutRocksResponse = () => {
    if (props.clickedResultsFound) {
      props.toggleCollectView()
    } else if (!props.clickedResultsFound) {
      props.toggleClickedResultsAlert()
    }
  }
    return (
      <div>
        <Toast isOpen={props.isOpen}>
          <ToastHeader icon="primary" onClick={toggle} >{props.selectedMapData.name}</ToastHeader>
          <ToastBody>
            <p>
                <Badge color="success" pill>
                    {props.selectedMapData.best_age_bottom} - {props.selectedMapData.best_age_top} million years
                </Badge>
                <br></br>
                <Badge color="warning" pill>{props.selectedMapData.age}</Badge>
            </p>
            {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididun */}
            <p></p>
            <Button color="primary" size="lg" block onClick={scoutRocksResponse}>Scout for Rocks</Button>
          </ToastBody>
        </Toast>
      </div>
    );
  }
  
  export default InfoToast;
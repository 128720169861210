import React, { useState, useEffect } from 'react';
import {
    Badge,
    Card,
    CardImg,
    CardImgOverlay,
    CardBody,
    CardSubtitle,
    CardTitle,
    CardText,
    CardColumns,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row, Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Spinner
   } from 'reactstrap';
import sampleImg from '../assets/sulfur_shadow.png' //sulfur_shadow.png, Serpentinite_sur_gneiss.jpg
import { Requests } from '../util/api/request'
import { useAuth } from '../contexts/AuthContext'


    const items = [
        {
          id: 1,
          altText: 'Slide 1',
          caption: 'Slide 1'
        },
        {
          id: 2,
          altText: 'Slide 2',
          caption: 'Slide 2'
        },
        {
          id: 3,
          altText: 'Slide 3',
          caption: 'Slide 3'
        }
      ];



const CollectViewInfoToast = (props) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [cardEntries, setCardEntries] = useState([])
    const [scoutButtonText, setScoutButtonText] = useState('Scout Here')
    console.log('cardEntries',cardEntries)
    // console.log('items',items)
    console.log("Cur data for CollectViewInfoToast: ",props.selectedMapData)

    const { currentUser } = useAuth()


    const ignoreItemList = ['sedimentary', 'igneous', 'metavolcanic', 'siliciclastic', 'plutonic', 'volcanic: felsic rocks','volcanic','mafic','igenous']
    useEffect(() => {
        
        let newCardEntries = []
        // console.log("USING EFFECT")
        
        async function getLithArrayDetails() {
          var a =  await Requests.geolUnitsByMapId(props.selectedMapData.map_id)
          .then(function (response) {
            // console.log("GeolUnitsByMapId Success response:", response)
            const lithsArray = response.data.success.data[0].liths
            
            return Requests.geolLithologiesByLithId(lithsArray.toString())
          })
          .catch(function (error) {
            console.log(error);
          })
          .then(function (response) {
            var lithsDetails = response.data.success.data
            
            return lithsDetails
          })
          .catch(function (error) {
            console.log(error);
          })
          return a
        }

        if (props.type === 'geology' && props.selectedMapData.map_id !== undefined) {
          
        var lithsArrayDetails = getLithArrayDetails().then(function (response) {
          // console.log("fin resp",response)
          setActiveIndex(0) // Setting index to ZERO Here, to reset carousel to beginning of list, prevent bug if higher index doesn't exist
          const responseFiltered = response.filter(item => {
            return !ignoreItemList.includes(item.name);
          });
          // console.log("FILTERED resp",responseFiltered)

          
          
          if (responseFiltered.length === 0) {
            // console.log("RESP ZERO")
            props.toggleClickedResultsAlert(false)
          } else {
            props.toggleClickedResultsAlert(true)
          }

          // Loop through each item and append data from the SoleMarks Firestore DB:
          responseFiltered.map((item) => {
            // console.log("Getting Solemarks data for item..",item)

            Requests.getGuideObjectByCommonName(item.name).then((results) => {
              // console.log("Guide Object results: ",results)
              item.img_url = results.img_url
              // Temporarily Modify definition to only be one sentence? using .split()
              item.definition = results.definition.split(".")[0] ?? ''

            })

          })


          setCardEntries(responseFiltered)

        })
       
        }
    }, [props.selectedMapData])

    function capitalize (string) {
      return string[0].toUpperCase() + string.slice(1)
    }

    function addToScoutBook(itemName) {
      console.log("Adding to scout book: ",itemName)
      Requests.addToScoutBookByGuideId2(itemName,props.selectedMapData)

      setScoutButtonText("Success")
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setScoutButtonText("Scout Here")
      }, 1000)
      // Requests.getGuideObjectByCommonName(itemName).then((guideId) => {
      //   console.log("Guide Object id to add to Scouting Book: ",guideId)
      // })
      
      // API Call to add to scout book, temp disabled..
      // Requests.addToScoutBookByGuideId(guideId)

    }

    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === cardEntries.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? cardEntries.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    }

    const slides = cardEntries.map((item) => {
        return (
          <CarouselItem
            className="custom-tag"
            tag="div"
            key={item.lith_id}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
          >

                <div 
                className='collectActiveCard'
                // className='collectCard'
                >
                  {/* <Col> */}
                      <Card
                      // style={{maxWidth: '100%', maxHeight: '100%'}}
                      className='collectCard'
                      style={{backgroundColor:'rgb(242, 242, 242)'}}
                      >  
                      
                        <CardImg
                        // style={{maxWidth: '100%', maxHeight: '100%'}}
                        className='collectCardImg'
                         src={item.img_url} alt="Image" />
                         {/* <CardBody>Name
                           </CardBody> */}
                <CardText style={{margin:'auto', textAlign:'center'}}>{props.selectedMapData.age} <b>{capitalize(item.name)}</b>  </CardText>  
              <Row style={{padding:'4px'}}>
              <Col 
              style={{marginLeft:'10px', alignItems:'center', justifyContent:'center', display:'flex'}}
              >
                <Button size="sm"onClick={() => addToScoutBook(item.name)}>{scoutButtonText}</Button>
                </Col>
                <Col>
                <Button size="sm"disabled color="warning" onClick={() => console.log("Add to bag..")}>Add to Bag</Button>
                </Col>
              </Row>
              </Card>
                  {/* </Col> */}
                </div>
                
            {/* <CarouselCaption className="text-primary" captionHeader={
              capitalize(item.name)
              
            } captionText={''
              // <Row>
              // <Col xs="6">
              //   <Button onClick={() => addToScoutBook(item.name)}>Scout Here</Button>
              //   </Col>
              //   <Col xs="6">
              //   <Button disabled color="warning" onClick={() => console.log("Add to bag..")}>Add to Bag</Button>
              //   </Col>
              //   </Row>
            } /> */}
          </CarouselItem>
        );
      });

    return (
        <div>
          { cardEntries.length > 0
            ? 
            <Modal isOpen={props.isOpen} toggle={props.toggle}>
              <ModalHeader style={{padding:'5px 10px 5px 10px', justifyContent:'center', textAlign:'center'}} toggle={props.toggle}>Rocks At This Spot</ModalHeader>
              <ModalBody>
              <style>
                  {
                  `.custom-tag {
                      max-width: 100%;
                      // height: 200px;
                      height: 50vh;
                      background-color: rgba(0,0,0,0.8);
                      // background-image: url('https://upload.wikimedia.org/wikipedia/commons/7/7f/Ural_mountains_3_448122223_93fa978a6d_b.jpg')
                      // background: hsla(9, 100%, 64%, 0.4);
                      // background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Canyon_River_Tree_%28165872763%29.jpeg/1280px-Canyon_River_Tree_%28165872763%29.jpeg')
                      
                    }`
                  }
              </style>
              
              <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
              >
                  <CarouselIndicators items={cardEntries} activeIndex={activeIndex} onClickHandler={goToIndex} />
                  {slides}
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
              </Carousel>
              </ModalBody>
              <ModalFooter style={{padding:'0px', justifyContent:'center'}}>
              <Row>
                <Col>
                <Badge color="primary" pill>{"Region: " + String(props.selectedMapData.name).substring(0,54)}</Badge>
                </Col>
              {/* </Row>
              <Row> */}
                {/* <Col>
                <Badge color="warning" pill>{props.selectedMapData.age}</Badge>
                </Col> */}
                {/* <Col> */}
                {/* <Button outline color="secondary" onClick={props.toggle}>Return</Button> */}
                {/* </Col> */}
                
              </Row>
                  
                  
              </ModalFooter>
            </Modal>
            : 
             <> </>
            
          }
        
        </div>
    )
}

export default CollectViewInfoToast;
import React, { useState } from 'react'
import { Button, ButtonGroup, ButtonToolbar,
    Card, CardImg
 } from 'reactstrap';
import boxClosedIcon from './../assets/icons/box-solid.svg'
import boxOpenIcon from './../assets/icons/box-open-solid.svg'
import zoomInIcon from './../assets/icons/search-plus-solid.svg'
import zoomOutIcon from './../assets/icons/search-minus-solid.svg'
import locateMeIcon from './../assets/icons/street-view-solid.svg'

export default function ToolKit(props) {

    const [toolKitOpen, setToolKitOpen ] = useState(false);
    const toggleToolKitOpen = () => setToolKitOpen(!toolKitOpen);
    return (
        <div className='toolKit'>
        <Card style={{padding:'.5rem'}}body>
            { toolKitOpen 
            ? <div>
            {/* <Button style={{borderColor:'ivory', color:'ivory'}} outline
            onClick={() => {props.mapLocateControl()}}
            >
            <CardImg style={{width:'20px'}} src={locateMeIcon} alt="Card image cap" />
            </Button> */}
            <Button style={{borderColor:'ivory', color:'ivory'}} outline
            onClick={() => {props.mapZoomControl('out')}}
            >
            <CardImg style={{width:'20px'}} src={zoomOutIcon} alt="Card image cap" />
            </Button>
            <Button style={{borderColor:'ivory', color:'ivory'}} outline
            onClick={() => {props.mapZoomControl('in')}}
            >
            <CardImg style={{width:'20px'}} src={zoomInIcon} alt="Card image cap" />
            </Button>
            <Button style={{borderColor:'ivory', color:'ivory'}} outline
            onClick={toggleToolKitOpen}
            >
            <CardImg style={{width:'20px'}} src={boxOpenIcon} alt="Card image cap" />
            </Button>
            </div>

            : <Button style={{borderColor:'ivory', color:'ivory'}} outline
            onClick={toggleToolKitOpen}
            >
            <CardImg style={{width:'20px'}} src={boxClosedIcon} alt="Card image cap" />
            </Button>
            }
        
        {/* <CardImg style={{width:'20px'}} src={boxClosedIcon} alt="Card image cap" /> */}
        {/* <CardImgOverlay>
          <CardTitle tag="h5">Card Title</CardTitle>
          <CardText>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</CardText>
          <CardText>
            <small className="text-muted">Last updated 3 mins ago</small>
          </CardText>
        </CardImgOverlay> */}
      </Card>
        {/* <Card body> */}
            {/* <ButtonToolbar>
                <ButtonGroup vertical>
                <Button
                onClick={() => {props.mapZoomControl('in')}}
                >+</Button>
                <Button
                onClick={() => {props.mapZoomControl('out')}}
                >-</Button>
                <Button
                onClick={() => {props.mapLocateControl()}}
                >*</Button>
            </ButtonGroup>
        </ButtonToolbar> */}
      {/* </Card> */}

    
        </div>
    )
}

import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Table } from 'reactstrap';
import { Requests } from './../util/api/request'

const ScoutingBook = (props) => {
  const {
    modal,
    toggle
  } = props;

  const [data, setData] = useState(null);
  console.log("Modal status: ",modal)


  // const fetchScoutData = () => {
  //   Requests.getScoutBookData()
  //     .then(results => setData(results));
  // }
  const fetchScoutData = () => {
    Requests.getScoutBookData()
      .then((results) => {
        console.log("Returned Scouting Items: ",results)
        setData(results)
      });
  }
  const removeFromScoutBook = (itemName) => {
    Requests.removeFromScoutBook(itemName).then((result) => {
      console.log("Refreshing..")
      console.log("data",data)
      var updatedData = data.filter(x => {
        return x.id != itemName;
      })
      console.log("upd data",updatedData)
      
      setData(updatedData)
    })
  }

  const centerMapOnItem = (item) => {
    console.log("center map and close modal clicked..",item)
    props.setCurrentScoutSelection((prevState) => ({
      ...prevState,
      clicked_lng: item.clicked_lng,
      clicked_lat: item.clicked_lat,
      clicked_zoom: item.clicked_zoom
    }))
    // props.mapItemCenter()
  }
  // console.log("data scoutbook",data)


  useEffect(() => {
    if (modal) {
      fetchScoutData()
    }
      
    }, [modal]);


  // useEffect(() => {
  //   const fetchScoutingData = async () => {
  //     const results = await Requests.getScoutBookData()

  //   }
  //   fetchScoutingData();
  //     }, []);


  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Scouting Book</ModalHeader>
        <ModalBody>
        <Table size="sm" borderless hover>
      <thead>
        <tr>
          <th>Rock</th>
          <th>Age</th>
          <th>Map Unit</th>
          {/* <th>View</th> */}
          
        </tr>
      </thead>
      <tbody>
          { 
          data ?
              data.map((item) => (
                  <tr
                  //  onClick={() => { centerMapOnItem() }} 
                   key={item.id}
                   >
                      <td id="cellMapName">{item.name}</td>
                      <td>{item.age}</td>
                      
                      <td>{item.map_name}</td>
                      <td><Button outline color="secondary" size="sm"
                      onClick={() => {centerMapOnItem(item)} }
                      ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
                    </svg></Button>{' '}</td>
                      <td><Button outline color="secondary" size="sm"
                      onClick={() => {removeFromScoutBook(item.id)} }
                      >x</Button>{' '}</td>
                      {/* <td></td> */}
                  </tr>
              ))
              : 'Loading..'
          }
        {/* <tr>
          <th scope="row">1</th>
          <td>Mark</td>
          <td>Otto</td>
          <td><Button outline color="secondary" size="sm">Remove</Button>{' '}</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>Jacob</td>
          <td>Thornton</td>
        </tr>
        <tr>
          <th scope="row">3</th>
          <td>Larry</td>
          <td>the Bird</td>
        </tr> */}
        
      </tbody>
    </Table>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
          <Button outline color="secondary" onClick={toggle}>Return</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ScoutingBook;
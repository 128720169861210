import React, { useRef, useState } from 'react'
import { Card, Button, CardTitle, CardText,
    Col, Form, FormGroup, Label, Input, FormText, Alert
 } from 'reactstrap';
 import { useAuth } from '../../contexts/AuthContext'
 import { Link, useHistory } from 'react-router-dom'
import ForgotPassword from '../ForgotPassword';

export default function DashboardProfile() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, updateEmail, updatePassword } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }

        const promises = []
        setLoading(true)
        setError('')
        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value))
        }

        if (passwordRef.current.value) {
            promises.push(updatePassword(passwordRef.current.value))
        }

        Promise.all(promises).then(() => {
            history.push('/')
        }).catch(() => {
            setError('Failed to update account')
        }).finally(() => {
            setLoading(false)
        })
        setLoading(false)

        
    }



    return (
        <div>
        <Card body >
            <CardTitle className="text-center" tag="h4">Update Profile</CardTitle>
            {error && <Alert color="danger"> {error} </Alert>}
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="exampleEmail" sm={2}>Email</Label>
                    <Col sm={10}>
                    <Input type="email" innerRef={emailRef} name="email" id="exampleEmail"
                    defaultValue={currentUser.email}
                    />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Label for="examplePassword" sm={2}>Password</Label>
                    <Col sm={10}>
                    <Input type="password" innerRef={passwordRef} name="password" id="examplePassword" placeholder="Leave blank to keep the same" />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Label for="examplePassword" sm={4}>Password Confirmation</Label>
                    <Col sm={10}>
                    <Input type="password" innerRef={passwordConfirmRef} name="password" id="examplePassword" placeholder="Leave blank to keep the same" />
                    <FormText>Confirm password.</FormText>
                    </Col>
                </FormGroup>
                <Button disabled={loading} color="primary" block>Update</Button>
            </Form>
        </Card>
        <div>
            <Link to='/'>Cancel</Link>
        </div>

        </div>
    )
}

import React, { useState }  from 'react'
import Map from './../../Map';
import Inventory from './../Inventory'
import NavBar from './../NavBar'


export default function SoleGeologyMain() {

    const [currentScoutSelection, setCurrentScoutSelection] = useState({})
    console.log("current scout selection: ",currentScoutSelection)

    return (
        <div>

        <NavBar />
      
        <Map
        currentScoutSelection={currentScoutSelection}
        />

        <Inventory
        setCurrentScoutSelection={setCurrentScoutSelection}
        />

        </div>
    )
}

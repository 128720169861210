import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';

const ClickedResultsAlert = (props) => {
//   const [visible, setVisible] = useState(true);

//   const onDismiss = () => setVisible(false);
    

    useEffect(() => {
        // if (props.isOpen) {
        //     console.log("timer executing..")
        //     props.toggle();
        // }
        if (props.isOpen) {
            console.log("alert is OPEN..")
            setTimeout(() => {
            props.toggle()
            }, 2000);

        }
        // console.log("timer executing..")
        // setTimeout(() => {
        //     props.toggle()
        // }, 1000);
            
        // when the component is mounted, the alert is displayed for 3 seconds
        // setTimeout(() => {
            
        //     if (props.isOpen) {
        //         console.log("timer executing..")
        //         props.toggle();
        //     }
            
        // }, 1000);
      }, [props.isOpen]);  

  return (
      <div style={{maxWidth:'50%', marginLeft:'10px', textAlign:'center'}}>
    <Alert color="info" isOpen={props.isOpen}
    //  toggle={onDismiss}
     >
      {props.alertText}
    </Alert>
    </div>
  );
}

export default ClickedResultsAlert;

import React, { useRef, useState } from 'react'
import { Card, Button, CardTitle, CardText,
    Col, Form, FormGroup, Label, Input, FormText, Alert
 } from 'reactstrap';
 import { useAuth } from '../contexts/AuthContext'
 import { Link, useHistory } from 'react-router-dom'

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()


    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            history.push("/")
        } catch {
            setError('Failed to log in')
        }
        setLoading(false)

        
    }

    return (
        <div>
        <Card body >
            <CardTitle className="text-center" tag="h4">Log in</CardTitle>
            {error && <Alert color="danger"> {error} </Alert>}
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="exampleEmail" sm={2}>Email</Label>
                    <Col sm={10}>
                    <Input type="email" innerRef={emailRef} name="email" id="exampleEmail" placeholder="Email" />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Label for="examplePassword" sm={2}>Password</Label>
                    <Col sm={10}>
                    <Input type="password" innerRef={passwordRef} name="password" id="examplePassword" placeholder="password" />
                    </Col>
                </FormGroup>
                <Button disabled={loading} color="primary" block>Log in</Button>
            </Form>
            <div>
                <Link to='/forgot-password'>Forgot Password?</Link>
            </div>
        </Card>
        <div>
            Need an account? <Link to='/signup'>Sign up</Link>
        </div>

        </div>
    )
}
